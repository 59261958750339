<template>
  <v-container fluid>
    <v-row
      justify="center"
    >
      <v-col cols="12" sm="8" md="7">
        <v-sheet
          color="white"
          elevation="6"
          outlined
          rounded
          class="ma-5 pa-5 mb-5"
        >
          <div class="text-center text-subtitle1 font-weight-large mb-5">
            <h4>{{$_strings.report.REPORT_LIST}}</h4>
          </div>
          <v-divider></v-divider>
          <div class="text-center mt-5">{{$_strings.report.SELECT_THE_REPORT}}</div>
          <v-select
            v-model="report"
            :loading="isLoading"
            outlined
            clearable
            dense
            class="mt-7"
            persistent-hint
            :items="itemsReport"
            placeholder="Pilih Laporan"
          ></v-select>
          <div class="d-flex justify-center">
            <v-btn
              class="primary"
              @click="navigateToReportFilter"
              :disabled="!report"
            >
              {{$_strings.report.NEXT}}
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'index',
  mounted() {
    this.fetchListReport();
  },
  data() {
    return {
      isLoading: false,
      itemsReport: [],
      report: '',
    };
  },
  methods: {
    async fetchListReport() {
      try {
        this.isLoading = true;
        const result = await this.$_services.report.listReports();
        result.forEach((item, groupIndex) => {
          this.itemsReport.push({ header: item.groupReportName });
          if (item.reports) {
            item.reports.forEach((itemReport, index) => {
              this.itemsReport.push({
                text: itemReport.reportName,
                value: itemReport.id,
                reportParameter: itemReport.reportParameter,
              });
              if (index + 1 === item.reports.length && result[groupIndex + 1]) {
                this.itemsReport.push({
                  divider: true,
                });
              }
            });
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    navigateToReportFilter() {
      this.$router.push({
        name: 'report-filter',
        params: {
          id: this.report,
        },
        query: {
          headerTitle: this.itemsReport.find((r) => r.value === this.report).text,
          reportParameter: this.itemsReport.find((r) => r.value === this.report).reportParameter,
        },
      });
    },
  },

};
</script>
